import React, { useMemo, useState } from 'react'
import {
    View,
    Image,
    TouchableOpacity,
    LayoutRectangle,
    StyleSheet,
} from 'react-native'
import { IDropDown, Item } from './interface'
import { styles } from './styles'
import OutsideClickHandler from '../../molecules/OutsideClickHandler'
import { Typography } from '../Typography/Typography'

const DropDown = (props: IDropDown) => {
    const {
        onSelect,
        placeHolder,
        dropdownIcon,
        placeHolderContainerStyle,
        headerTextStyle,
        placeHolderLabel,
        placeHolderTextStyle,
        testID,
        listContainerStyle,
        textStyle,
        selectedListContainerStyle,
        zIndex,
        containerStyle,
        contentContainerStyle,
        options,
        placeHolderLabelTypographyProps,
        headerTypographyProps,
        itemTypographyProps,
    } = props
    const [isVisible, setIsVisible] = useState(props.initialVisibility || false)
    const [headerLayout, setHeaderLayout] = useState<LayoutRectangle>(undefined)
    const [value, setValue] = useState(null)

    const onPressPlaceHolder = () => {
        setIsVisible((prevIsVisible) => {
            const newIsVisible = !prevIsVisible
            return newIsVisible
        })
    }

    const onItemPress = (item: Item) => {
        setValue(item)
        setIsVisible(false)
        onSelect?.(item)
    }

    const renderDropDownHeader = useMemo(() => {
        return (
            <TouchableOpacity
                testID={`${testID}-headerButton`}
                onPress={onPressPlaceHolder}
                style={[styles.placeHolderContainer, placeHolderContainerStyle]}
                onLayout={(e) => {
                    setHeaderLayout(e.nativeEvent.layout)
                }}
            >
                {value?.image && (
                    <View style={styles.iconContainer}>{value.image}</View>
                )}
                {placeHolderLabel && (
                    <Typography
                        style={StyleSheet.flatten([
                            styles.placeholderLabelTextStyle,
                            placeHolderTextStyle,
                        ])}
                        {...placeHolderLabelTypographyProps}
                    >
                        {placeHolderLabel}
                    </Typography>
                )}
                <View style={styles.dropdownContainer}>
                    <Typography
                        style={StyleSheet.flatten([
                            styles.placeholderTextStyle,
                            headerTextStyle,
                        ])}
                        numberOfLines={1}
                        {...headerTypographyProps}
                    >
                        {value?.name ? value?.name : placeHolder}
                    </Typography>
                    <View style={styles.imageContainer}>
                        {dropdownIcon ? (
                            dropdownIcon
                        ) : (
                            <Image
                                resizeMode="contain"
                                style={[styles.image]}
                                source={{
                                    uri: 'https://i.imgur.com/aXzX4Z7.png',
                                }}
                            />
                        )}
                    </View>
                </View>
            </TouchableOpacity>
        )
    }, [placeHolderLabel, value?.name])

    const renderDropDownContent = useMemo(() => {
        return options.map((item: any, index: any) => {
            const isActive = item?.key == value?.key
            return (
                <TouchableOpacity
                    testID={`${testID}-content-${index}`}
                    activeOpacity={1}
                    delayPressOut={80}
                    onPress={() => onItemPress(item)}
                    style={[
                        styles.listContainer,
                        isActive
                            ? {
                                  ...styles.selectedListContainer,
                                  ...(selectedListContainerStyle as object),
                              }
                            : listContainerStyle,
                    ]}
                    key={index}
                >
                    {item.image ? (
                        <View style={styles.iconContainer}>{item.image}</View>
                    ) : null}
                    <Typography
                        style={StyleSheet.flatten([
                            styles.placeholderTextStyle,
                            textStyle,
                        ])}
                        numberOfLines={1}
                        {...itemTypographyProps}
                    >
                        {item?.name}
                    </Typography>
                </TouchableOpacity>
            )
        })
    }, [options, value, headerLayout])

    return (
        <View
            testID={testID}
            style={[styles.container, zIndex ? { zIndex } : {}, containerStyle]}
        >
            {renderDropDownHeader}
            {options.length > 0 && isVisible && headerLayout ? (
                <View
                    testID={`${testID}-dropDown`}
                    style={[
                        styles.contentContainer,
                        {
                            top: headerLayout.height + headerLayout.y,
                            left: headerLayout.x,
                        },
                        contentContainerStyle,
                    ]}
                >
                    <OutsideClickHandler
                        onPress={() => {
                            setIsVisible(false)
                        }}
                    >
                        {renderDropDownContent}
                    </OutsideClickHandler>
                </View>
            ) : null}
        </View>
    )
}

export default DropDown
